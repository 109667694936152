.popular-course-container {
    margin-top: 0rem;
}
.most-line-container{
    padding: 2rem;
}

.custom-modal-body span{
    color: black;
    
}
.divoo{
    margin-top: 7px;
}

.most-line {
    font-family: "Montserrat" ;
    color: #06241B;
    font-size: 50px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: -0.02em;
    text-align: left;
    padding-left: 8% !important;
}

.second-carddd-roww {
    margin-top: 1rem !important;
}

.popular-line {
    color: #0166FE;
}

.various-version {
    font-family: "Public Sans";
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    color: #333333;
}

.btn-see {
    width: 12%;
    border-radius: 35px !important;
    background-color: #0166FE !important;
    font-family: "Montserrat" !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 23.5px !important;
    text-align: center;
    border: none !important;
    margin-top: 2%;
    margin-bottom: 5%;

}

@media (max-width: 767px) {
    .most-line {
        font-size: 27px;
        display: flex;
        justify-content: center;

    }

    .popular-line {
        margin-left: 0.4rem;
    }

    .various-version-row {
        align-items: center !important;
        text-align: center !important;
    }

    .btn-see {
        width: 38%;
    }

    .second-carddd-roww {
        margin-top: 0;
    }

        .btn-see {
            margin-bottom: 1%;
    
        }



}
.custom-modal .modal-body {
    max-height: 70vh; /* Adjust the height as needed */
    overflow-y: auto;
  }
  
  @media (max-width: 768px) {
    .custom-modal .modal-body {
      max-height: 60vh;
    }
  }
  