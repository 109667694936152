.students-container {
  background-color: #eef1f2;
  padding: 4rem;
}

.students-first-col {
  font-family: "Montserrat";
  font-size: 54px;
  font-weight: 600;
  line-height: 90.21px;
  letter-spacing: -0.02em;
  padding-left: 8% !important;
}

.students-first-col-span {
  color: #0166fe;
}

.students-second-col {
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  padding-left: 8% !important;
}

.student-card {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin: 10px;
  padding: 15px;
  /* width: 322px; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  /* width: 100%; */
}

.student-card-img {
  width: 100%;
  object-fit: contain;
  /* height: auto; */
  /* height: 272.4px; */
}

.student-name {
  font-family: Public Sans;
  font-size: 19px;
  font-weight: 500;
  line-height: 28.2px;
  text-align: left;
  margin: 10px 0 0 0;
}

.student-title {
  font-family: Public Sans;
  font-size: 17px;
  font-weight: 400;
  line-height: 21.15px;
  text-align: left;
  margin: 10px 0 0 0;
}

.student-carousel {
  height: 30rem;
}

@media (min-width: 464px) and (max-width: 551px) {
  .student-title {
    font-size: 11px;
    margin: 2px 0 0 0;
  }

  .student-name {
    font-size: 16px;
  }
}

@media (min-width: 550px) and (max-width: 766px) {
  .student-title {
    font-size: 13px;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .student-title {
    font-size: 12px;
    margin: 2px 0 0 0;
  }

  .student-name {
    font-size: 17px;
  }
}

@media (min-width: 1024px) {
  .student-title {
    font-size: 15px;
  }
}

@media (min-width: 766px) and (max-width: 1025px) {
  .students-first-col {
    padding-left: calc(var(--bs-gutter-x) * 2);
    line-height: 65px;
  }

  .students-second-col {
    padding-left: calc(var(--bs-gutter-x) * 2);
  }
}

@media (max-width: 766px) {
  .students-first-col {
    padding-left: calc(var(--bs-gutter-x) * 1.2);
    line-height: 40px;
    font-size: 39px;
  }

  .students-second-col {
    padding-left: calc(var(--bs-gutter-x) * 1.2);
    margin-top: 20px !important;
    font-size: 19px;
  }

  .students-first-col {
    text-align: center;
  }
}
