.CodeNavContaint {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.itemsContainerNav {
    display: flex;
}

.itemsContainerNavContaint {
    width: 100%;
}


.boyimagecontainer {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.firstdivContainer {
    width: 100%;
}

.containttext {
    padding-left: 3%;
    margin-top: 9%;
}

.imagacolomn {
    margin-top: 3%;
}

.paragraphContaint {
    margin-top: 2%;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 22px;
    color: #333333;
    margin-bottom: 0;
}


.contact-input-group {
    width: 323px;
    border-bottom: 1px solid #010100;
    border-left: 1px solid #010100;
}

.contact-icon-container {
    background-color: transparent;
    border: none;
}

.contact-icon {
    width: 1rem;
    height: 1rem;
    color: #0166fe;
}

.contact-input {
    border: none !important;
    box-shadow: none;
}

.contact-submit-button {
    margin-left: 1rem;
}


.paragraphArrow {
    padding: 0;
    margin: 0;
    border: 1px solid;
    background-color: #0166FE;
    color: white;
    padding-left: 8px;
    padding-top: 3px;
    padding-right: 10px;
    padding-bottom: 6px;
    font-size: 19px;
    margin-left: 45px;
}


.contact-icon-container {
    border: none !important;
    background: white !important;
}

.mainimagecontainer {
    margin-left: -17%;
    
    width: 100%;
}


.textContaint {
    text-align: center;
}

.textContaintItems {
    font-family: 'Montserrat';
    font-weight: 700;
    margin-top: 3%;
}

.innertext {
    color: #0166FE;
}

.marquetaginheader.row {
    margin-top: 4% !important;
    background-color: #EEF1F2 !important;
}


.firtsheadertag {
    font-size: 66px;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
}



.downloadbtn{
    display: flex;
    align-items: center;
    background-color:#0166FE;;
    padding: 2%;
    color: white;
    font-weight: 500;
    font-family: "Montserrat";
    border: none;
    border-radius: 8px;
    gap: 6px;
}

.contact-form-container {
    display: block;
    display: inline-flex;
    align-items: center;
    position: relative;
    left: 3.5rem;
    top: 2.5rem;
    padding: 9.3px;
    width: 61% !important;
}
.small-screen-button{
    display: none;
}
@media (max-width:574px) {
        .small-screen-button {
                display: block;
                width: 41%;
                margin-top: 1rem;
                padding: 1%;
            }
    .contact-form-container{
        display: none !important;
    }
        .container-small-card {
            /* text-align: center !important; */
            display: flex !important;
            justify-content: center !important;
            align-items: center !important;
            width: 100%;
        }
}

@media (min-width:1300px) and (max-width:1400px) {
    .firtsheadertag {
        font-size: 58px;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
    }
}

@media (min-width:994px) and (max-width:1299px) {
    .firtsheadertag {
        font-size: 50px;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
    }
}

@media (min-width:700px) and (max-width:993px) {
    .firtsheadertag {
        font-size: 38px;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
    }
}

@media (max-width:575px) {
    .CodeNavContaint {
        flex-direction: column;
    }

    .firtsheadertag {
        font-size: 31px;
        text-align: center;
    }

    .paragraphContaint {
        text-align: center;
    }

    .mainimagecontainer {
        margin-left: 0% !important;
    }
    .contact-form-container {
        width: 100%;
        display: inline-flex;
        align-items: center;
        position: relative;
        background-color: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        left: 0rem !important;
        top: 0rem !important;
        padding: 9.3px;
    }

    .textContaintItems {
        font-family: 'Montserrat';
        font-weight: 700;
        margin-top: 0.5%;
    }

    .containttext {
        padding-left: 0%;
    }
    
}

.no-outline:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: none !important;
}

.course-desc {
    left: 0;
    right: 0;
    text-align: center;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(5px);
    margin-top: -6rem;
}

.course-desc h5 {
    margin: 0;
    font-size: clamp(1rem, 2vw, 1.5rem);
    line-height: 1.5;
}

.coursedesc {
    font-family: "Montserrat";
    font-weight: 700;
    margin-bottom: 0;
}

.coursename {
    color: #0166FE;
}

@media (max-width:1200px) {
    .contact-form-container {
        left: 0rem;
        top: 0rem;
    }
}

@media (max-width: 575px) {


}