.discover-our {
    align-items: center !important;
    text-align: center !important;
}

.discover-our-col {
    font-family: "Montserrat";
    color: black;
    font-weight: 700;
    font-size: 21px;
    line-height: 29px;
    margin-top: 3rem !important;
}

.teacher-our {
    font-family: "Montserrat";
    color: black;
    font-weight: 400;
    font-size: 17px;
    line-height: 19px;
    display: flex !important;
    justify-content: center !important;
    margin-top: 1.5rem !important;

}


.btn-see456 {
    border-radius: 35px !important;
    background-color: #0166FE !important;
    font-family: "Montserrat" !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 23.5px !important;
    text-align: center;
    border: none !important;
    margin-top: 2%;
    margin-bottom: 5%;
    padding-left: 1.4rem !important;
    padding-right: 1.4rem !important;

}
