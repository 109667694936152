 .mainNavBarContainer {
    transition: transform 0.4s ease-in-out !important;
    position: fixed !important;
    width: 100% !important;
    z-index: 1000 !important;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    background-color: rgba(255, 255, 255, 0.2) !important;
    backdrop-filter: blur(10px) !important;
    -webkit-backdrop-filter: blur(10px) !important;
}

.mainNavBarContainer:not(.mobile) {
    transform: translateY(-100%) !important;
}

.mainNavBarContainer.show:not(.mobile) {
    transform: translateY(0) !important;
}

.mainNavBarContainer.mobile {
    position: static !important;
    transform: none !important;
}

.CodeimageContainer {
    width: auto;
    max-width: 150px;
}

.navBarImageCodeai {
    width: 100%;
    height: auto;
    object-fit: contain;
}

.CodeNavContainttags {
    display: flex;
    gap: 1rem;
}

.navContained {
    font-family: "Montserrat", sans-serif;
    text-decoration: none;
    margin: 0;
    padding: 0;
    font-weight: 600 !important;
    color: #010100;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttonsnavbarConatiner {
    display: flex;
    gap: 10px;
}

.FirstbuttonNavBar,
.SecondbuttonNavBar {
    padding: 0.5rem 1rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.FirstbuttonNavBar {
    background-color: white;
    border: 2px solid blue;
    color: black;
}

.SecondbuttonNavBar {
    background-color: blue;
    color: white;
}

@media (max-width: 574px) {
    .buttonsnavbarConatiner {
        flex-direction: row;
        justify-content: center;
        width: 100%;
    }

    .FirstbuttonNavBar,
    .SecondbuttonNavBar {
        width: auto;
    }

    .navbar-collapse {
        background-color: white;
        padding: 1rem;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }

    .CodeNavContainttags {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 1rem;
    }

    .navContained {
        padding: 0.5rem 0;
    }
    .navContainer{
        z-index: 99999;
    }
} 
