

.skills-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
}
.team-first-col-container{
    padding: 2rem;
}
.team-first-col{
    padding-left: 8% !important;
}
.skills-highlight {
    color: #007bff;
}

.skill-image-container {
    width: 100%;
    padding-bottom: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto;
}

.skill-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    object-fit: fill;
}

.skill-name {
    margin-top: 10px;
     font-size: 15px;
}

@media (max-width: 767px) {
    .skills-title {
        font-size: 2rem;
    }

    .skill-name {
        font-size: 12px;
    }
}