.terms-conditions-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
  }
  
  .terms-conditions-container h1 {
    text-align: center;
    color: #2c3e50;
    margin-bottom: 20px;
  }
  
  .section {
    margin-bottom: 20px;
  }
  
  h2 {
    color: #2980b9;
    margin-bottom: 10px;
  }
  
  p {
    color: #7f8c8d;
    margin-bottom: 10px;
  }
  