.our-team-containt {
    font-family: "Montserrat";
    font-size: 54px;
    font-weight: 600;
    line-height: 90.21px;
    letter-spacing: -0.02em;
}


h1.meet_team {
    text-align: center;
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 55px;
}

.team-first-col-span {
    color: #0166fe;
}
.team-desc{
    color: rgb(95, 117, 117);
    font-family: 'Roboto';
    font-weight: 700;
    text-align: center;
    font-size: 55px;
}
.team-desc-description {
    color: rgb(95, 117, 117);
    font-family: "Roboto";
    text-align: center;
    font-weight: 500;
}



.our-team-photo {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 50%;
    aspect-ratio: 1/1;
}

.image-container-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.teamRowimages.row {
    margin-top: 3%;
    margin-bottom: 5%;
}
.designationContaint{
    margin: 0;
}
@media (max-width: 576px) {
    .our-team-photo {
        width: 80%;
        height: auto;
    }
}

@media (min-width: 577px) {
    .our-team-photo {
        width: 70%;
        height: auto;
    }
}
@media (max-width:550px) {
    .team-desc {
            font-size: 35px;
        }
        h1.meet_team {
            text-align: center;
            font-family: 'Roboto';
            font-weight: 700;
            font-size: 35px;
        }
         .our-team-containt {
           font-family: "Montserrat";
           font-size: 54px;
           font-weight: 600;
           line-height: 90.21px;
           letter-spacing: -0.02em;
           text-align: center;
         }

}