.image-container {
    background-image: url('../../Assets/unsplash_LpbyDENbQQg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100% !important;
    min-height: 95vh;
    color: white;
    padding: 9% 6% 6%;
    box-sizing: border-box;
    border-radius: 15px;
    margin-top: 5%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.community-form-card {
    background: rgba(255, 255, 255, 0.4) !important;
    backdrop-filter: blur(12px) !important;
    padding: 2rem;
    width: 100%;
    max-width: 31rem;
    margin-left: 6%;
    border-radius: 19px !important;
    margin-top: -3%;
}

.readyyyy {
    font-family: "Montserrat";
    font-size: 32px;
    font-weight: 600;
    line-height: 44px;
    text-align: left;
    color: #111111;
}

.long-established {
    font-family: "Montserrat";
    font-size: 14.9px;
    font-weight: 400;
    line-height: 18.38px;
    color: #333333;
    margin-top: 1rem !important;
}

.community-formm {
    margin-top: 1.6rem;
}

.last-nameee {
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 500 !important;
    line-height: 19.5px;
    color: #333333;
    margin-top: 0.7rem;
}

input.first-name-plh.form-control {
    background-color: rgba(255, 255, 255, -0.6) !important;
    border: 0.7px solid #666666 !important;
}

.first-name-plh::placeholder {
    color: #333333CC !important;
    font-family: "Montserrat" !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 19.5px;
}

.message-roww {
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    margin-top: 1rem !important;
}

.message-buttonnn {
    border-radius: 25px !important;
    font-family: "Montserrat" !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 26.82px;
    text-align: center;
    padding: 1.5%;
    width: 100%;
}

.paddingInName {
    padding: 4% !important;
}

.paddingInMessage {
    padding: 2% !important;
}

.first-name-plh:focus,
.paddingInName:focus,
.paddingInMessage:focus {
    outline: none !important;
    box-shadow: none !important;
}

.textareaInput{
    outline: none !important;
        box-shadow: none !important;
        color: #333333CC !important;
            font-family: "Montserrat" !important;
            font-size: 14px !important;
            font-weight: 500 !important;
            line-height: 19.5px;
        background-color: rgba(255, 255, 255, -0.6) !important;
            border: 0.7px solid #666666 !important;
}

@media (max-width: 780px) {
    .image-container {
        justify-content: center;
        padding: 6%;
        min-height: 100vh;
    }

    .community-form-card {
        padding: 1.5rem;
        max-width: 100%;
        margin-left: 0%;
        margin-top: 0%;
    }

    .readyyyy {
        font-size: 26px;
        line-height: 34px;
        text-align: center;
    }

    .long-established-roww {
        text-align: center;
    }
}