.card-contain456 {
    padding: 0.5rem;
    border: none !important;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1),
        3px 0 7px rgba(0, 0, 0, 0.1),
        -5px 0 7px rgba(0, 0, 0, 0.1);
    width: 22rem;
    margin-top: 2rem;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    cursor: pointer;
}

.card-contain456:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(200, 20%, 70%);
    }

    100% {
        background-color: hsl(200, 20%, 95%);
    }
}

.skeleton-text {
    height: 1em;
    margin-bottom: 0.5em;
    border-radius: 0.25em;
    background-color: hsl(200, 20%, 80%);
}

.skeleton-image {
    width: 100%;
    background-color: hsl(200, 20%, 80%);
}

.image-container {
    width: 100%;
    overflow: hidden;
}

@media (max-width: 767px) {
    .card-contain456 {
        margin-top: 1.2rem;
        width: 20rem;
    }
}