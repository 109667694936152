.ailogooooo {
  height: 60px;
  margin-top: 4rem;
}

.footerrcoll {
  font-family: "Iceland";
  font-size: 40px;
  font-weight: 600;
  line-height: 40.74px;
  margin-top: 0.7rem !important;
}

.cdk-rowss {
  padding-left: 2rem;
  padding-right: 2rem;
}

.cdk-sol-pvt {
  margin-top: 2rem !important;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 560;
  line-height: 25px;
  letter-spacing: 0.6px;
  text-align: center;
  padding: 0;
}

.lastimagee {
  height: 100px;
  width: 100%;
  object-fit: cover;
}
.last-texttttt {
  position: absolute;
  margin-top: -5rem;
  margin-left: 3rem !important;
}
.last-textttt {
  padding: 10px;

  color: white;
text-align: center;
  border-radius: 5px;
  
  
  z-index: 100;
}
.last-textttt span {
  margin-left: 10px;
  cursor: pointer;

}
.last-textttt span:hover {
text-decoration: underline !important;
  }

.lastimage-container8144 {
  position: relative;
  height: 100px;
}

.iconns-rows {
  padding: 1rem;
  padding-left: 5.5rem;
  padding-right: 5.5rem;
  text-align: center;
  align-items: center;
  margin-bottom: 2rem;
}

.iconsss-div {
  background-color: white;
  display: flex;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1), 3px 0 7px rgba(0, 0, 0, 0.1),
    -5px 0 7px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  padding: 0.2rem !important;
  cursor: pointer;
  width: 92%;
  height: 4vh !important;
  justify-content: center;
  align-items: center;
}

@media (min-width: 500px) and (max-width: 800px) {
  .iconsss-div {
    width: 60% !important;
  }
}

.icon-facebook {
  font-size: 19px;
  padding: 0 !important;
}

.icon-youtube {
  font-size: 22px;
  padding: 0 !important;
}

.FooterContainer {
  margin-top: 4%;
}

@media (max-width: 1024px) {
  .cdk-rowss {
    padding-left: 0;
    padding-right: 0;
  }

  .iconns-rows {
    padding: 1rem;
    padding-left: 3rem;
    padding-right: 3rem;
    text-align: center;
    align-items: center;
    margin-bottom: 2rem;
  }
}

@media (max-width: 500px) {
  .FooterContainer {
    margin-top: 2%;
  }
  .last-texttttt{
    margin-left: 0rem !important; 
  }
}
