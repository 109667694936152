/* .imgo {
    height: 94%;
    width: 17rem;
    margin: 0px 50px;
    padding: 5px;

}

.imgo12 {
    height: 98%;
    width: 28rem;
    margin: 0px 50px;
    padding: 5px;

}

.imgo1245 {
    height: 99%;
    width: 29rem;
    margin: 0px 50px;
    padding: 5px;

}

.imgo234 {
    height: 80%;
    width: 7rem;
    margin: 0px 50px;
    padding: 5px;

}

.imgo123 {
    height: 82%;
    width: 8rem;
    margin: 0px 50px;
    padding: 5px;

}

.io {
    background-color: #EEF1F2;
    padding: 0px;
    margin-top: 2rem;

}

.op {
    margin-left: 23rem;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    margin-bottom: 20px;
}

.oo {

    color: #0166FE;
} */

.imgo {
    height: 50%;
    width: 15rem;
    margin: 0px 50px;
    padding: 5px;
    padding-top: 0;
    padding-bottom: 3px;

}

.imgo12 {
    height: 50%;
    width: 23rem;
    margin: 0px 50px;
    padding: 5px;
    padding-top: 0;
    padding-bottom: 3px;

}

.imgo1245 {
    height: 50%;
    width: 25rem;
    margin: 0px 50px;
    padding: 5px;
    padding-top: 0;
    padding-bottom: 3px;

}

.imgo234 {
    height: 50%;
    width: 6rem;
    margin: 0px 50px;
    padding: 5px;
    padding-top: 0;
    padding-bottom: 3px;

}

.imgo123 {
    height: 50%;
    width: 7rem;
    margin: 0px 50px;
    padding: 5px;
    padding-top: 0;
    padding-bottom: 3px;

}

.io {
    background-color: #EEF1F2;
    padding: 0px;
    margin-top: 3%;

}

.op {
    margin-left: 23rem;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    margin-bottom: 20px;
}

.oo {

    color: #0166FE;
}