.custom-modal {
 /* Adjust this value as needed */
  max-height: 100vh !important;
  overflow-y: auto !important;
}
.show{
    max-height: none !important;
  
}

.custom-modal-body {
  overflow-y: auto;
  height: auto;
}
.rr{
    font-weight: 500;
}

.card-contain {
    padding: 0.5rem;
    border: none !important;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1),
        3px 0 7px rgba(0, 0, 0, 0.1),
        -5px 0 7px rgba(0, 0, 0, 0.1);
    width: 22rem;

    margin-top: 1.2rem;
}

.machine-learning {
    font-family: "Public Sans";
    font-size: 18px;
    font-weight: 600;
    line-height: 20.2px;
    text-align: center;
    color: #1B1D1F;
}

.codeaixperrt {
    font-family: "Public Sans";
    font-size: 15px;
    font-weight: 400;
    line-height: 18.15px;
    text-align: left;
    color: #363A3D;
}

.star-component .MuiRating-iconFilled {
    color: blue;
}

.star-component .MuiRating-iconFilled,
.star-component .MuiRating-iconEmpty,
.star-component .MuiRating-iconHover {
    font-size: 17px;
    margin-right: 0.4rem;
}

.hr-classss {
    border: 1.7px solid #D0D0D0
}

.arrow-class-btn {
    display: flex;
    justify-content: flex-end;
}

.rs-499 {
    font-family: "Public Sans";
    font-size: 20px;
    font-weight: 600;
    line-height: 29.6px;
    text-align: left;
    color: #1B1D1F;
}

button.aroow-icooon-btn.btn.btn-primary {
    background: linear-gradient(to right, blue, rgb(34, 93, 176));
    /* background-color: rgba(0, 0, 255, 0.573); */

    border: none;
}

.arrow-icooonnn {
    font-size: 19px;
    margin-top: -0.3rem;
}


.card-contain {
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    cursor: pointer;
}

.card-contain:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}


.skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
}
.aroow-icooon-btn:hover{
    color: none;
}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(200, 20%, 70%);
    }

    100% {
        background-color: hsl(200, 20%, 95%);
    }
}

.skeleton-text {
    height: 1em;
    margin-bottom: 0.5em;
    border-radius: 0.25em;
}

.skeleton-image {
    width: 100%;
    background-color: hsl(200, 20%, 80%);
}

@media (max-width: 767px) {
    .card-contain {
        margin-top: 1.2rem;
        width: 20rem;
    }
}