.Feedback-container {
  padding: 2rem;
}
.Feedback-first-col {
  font-family: "Montserrat";
  font-size: 54px;
  font-weight: 600;
  line-height: 90.21px;
  letter-spacing: -0.02em;
  padding-left: 8% !important;
}
.Feedback-first-col-span {
  color: #0166fe;
}
.Feedback-second-col {
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  padding-left: 8% !important;
}
.Feedback-card-vector {
  width: 50%;
}
.Feedback-card {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin: 10px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  overflow: scroll;
}
.Feedback-card::-webkit-scrollbar {
  display: none;
}
.Feedback-card-img {
  width: 82px;
  height: 82px;
  object-fit: contain;
  border-radius: 50%;
}
.Feedback-name {
  font-family: "Public Sans";
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.002em;
  margin: 0;
}
.Feedback-div {
  display: inline-block;
}
.Feedback-card-left-div {
  display: flex;
  align-items: center;
  gap: 10px;
}
.Feedback-card-header {
  display: flex;
  justify-content: space-between;
}
.vector-div {
  width: 15%;
  text-align: right;
  display: inline-block;
  justify-content: space-between;
}
.Feedback-card-para {
  margin-top: 10px;
}
.Feedback-title {
  font-family: "Public Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
}
.Feedback-carousel {
  height: 25rem;
 
}
.Feedback-card-container {
  margin-top: 0 !important;
  
}

.Feedback-card-container
  > .react-multi-carousel-list
  > .react-multiple-carousel__arrow {
  position: absolute;
  bottom: 1px;
  transform: scale(0.8);
  font-size: 20px;
  border-radius: 6px !important;
  width: 35px;
  height: 35px;
  
}

.feedback-buttons {
  display: flex;
  justify-content: end;
  gap: 10px;
  cursor: pointer;
  font-size: 1.5rem;
  position: relative;
  bottom: 30px;
  z-index: 1;
  justify-content: end;
  gap: 20px;
  padding-right: 10px;
}

.feedback-arrow-left,
.feedback-arrow-right {
  /* left: 80%; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: whitesmoke;
  padding: 5px;
  border-radius: 5px;
  border: none;
  box-shadow: -0.2px 0.2px 3px rgb(163, 161, 161);
}

.feedback-arrow-left.clicked,
.feedback-arrow-right.clicked {
  background-color: blue;
  color: white;
}

@media (max-width: 551px) {
  .Feedback-first-col {
    font-size: 33px;
    line-height: 45px;
  }
  .Feedback-second-col {
    font-size: 16px;
    line-height: 24px;
  }
}
@media (min-width: 766px) and (max-width: 1000px) {
  .Feedback-card {
    padding: 12px;
    height: 300px !important;
  }
}
@media (max-width: 465px) {
  .Feedback-card {
    padding: 12px;
    height: 300px;
    overflow: scroll;
  }
  .Feedback-first-col {
    text-align: center;
  }
  .Feedback-second-col {
    text-align: center;
  }
  .feedback-buttons {
    display: flex;
    justify-content: end;
    bottom: -10px;
    z-index: 1;
    justify-content: center;
    gap: 20px;
    padding: 8px 0;
  }
}
@media (min-width: 464px) and (max-width: 1000px) {
  .vector-div {
    display: none;
  }
  .Feedback-card {
    padding: 12px;
    height: 400px;
    overflow: scroll;
  }
}
.show-more-text {
  color: blue;
  font-family: "Public Sans";
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  display: inline-block;
  padding-left: 5px;
  text-decoration: underline;
}
@media (min-width: 1000px) {
  .Feedback-card {
    height: 250px;
  }
}