.container-background-image {
    background-image: url('../../Assets/CodeBackgroung.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 90vh;
    width: 100%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9% 9%;
}

.form-container {
    background: linear-gradient(to right, #3073d7, #3563e3);
    border-radius: 10px;
    padding: 5%;
}

.arrow-icon-container {
    display: flex;
    justify-content: end;
    cursor: pointer;
    align-items: center;
}

.horizontal-line {
    height: 1px;
    background-color: white;
    margin-top: 5px;
}

.hide {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out, padding 0.5s ease-out;
    padding-top: 0;
        margin-bottom: 0;
}

.show {
    max-height: 300px;
    overflow: hidden;
    transition: max-height 0.5s ease-in, padding 0.5s ease-in;
    padding-top: 0.5rem;
    margin-bottom: 0.6rem;
}

.contactUsquestions {
    margin-bottom: 0;
    margin-top: 7px;
    font-weight: 600;
    font-family: 'Montserrat';
}
.conatactUsanswer {
    padding-left: 17px;
    font-family: 'Montserrat';
}

.ConatctUsButtontext{
    margin: 0;
    font-weight: 500;
    color: black;
}

.buttonmainContainer{
    text-align: center;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    margin-top: 3%;
    gap: 17px;
}

.contsactUsQuestion {
    margin: 0;
    color: #FAFAFA;
    font-weight: 500;
    font-family: 'Montserrat';
}

.contsactUsQuestionContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}
.ConatctUsButton {
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: white;
    padding-left: 18px;
    padding-right: 18px;
    border-radius: 7px;
    padding: 1%;
    padding-left: 23px;
    padding-right: 23px;
    cursor: pointer;
}
.CurSor{
    cursor: pointer;
}
@media (max-width: 768px) {
    .form-container {
        font-size: 14px;
    }
}
@media (max-width: 768px) {
    .container-background-image{
    min-height: 70vh;
    }
}
@media (max-width:432px) {
    .buttonContainer{
        flex-direction: column;
    }
    .ConatctUsButton{
        justify-content: center;
        padding-top: 8px;
        padding-bottom: 8px;
    }
        .show {
            max-height: fit-content;
            overflow: hidden;
            transition: max-height 0.5s ease-in, padding 0.5s ease-in;
            padding-top: 0.5rem;
            margin-bottom: 0.6rem;
        }
}
@media (max-width:500px) {
    .conatactUsanswer{
        padding-bottom: 1rem !important;
    }
}

@media (max-width: 1000px) {
    .container-background-image {
        min-height: auto;
        padding: 50px 5%;
    }

    .form-container {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .container-background-image {
        padding: 30px 5%;
        /* Further reduce padding for smaller screens */
    }

    .form-container {
        font-size: 14px;
    }
}